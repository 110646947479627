import React, { useEffect } from 'react';

const YandexAuthButton = () => {
  useEffect(() => {
    const initYandexAuthSuggest = async () => {
      const oauthQueryParams = {
        client_id: process.env.REACT_APP_YOUR_YANDEX_CLIENT_ID,
        response_type: 'token',
        redirect_uri: 'https://ra.nov.ru/token-redirect',
      };
      const tokenPageOrigin = 'https://ra.nov.ru/';

      const suggestParams = {
        view: 'button',
        parentId: 'yandex-auth-container',
        buttonView: 'white',
        buttonTheme: 'light',
        buttonSize: 'm',
        buttonBorderRadius: 8,
      };

      try {
        console.log('Инициализация YaAuthSuggest...');
        const { handler } = await window.YaAuthSuggest.init(
          oauthQueryParams,
          tokenPageOrigin,
          suggestParams
        );
        console.log('YaAuthSuggest инициализирован успешно.');

        console.log('Вызов обработчика YaAuthSuggest...');
        await handler();
        console.log('Обработчик YaAuthSuggest выполнен успешно.');
      } catch (error) {
        console.log('Ошибка обработки:', error);
      }
    };

    if (window.YaAuthSuggest) {
      console.log('YaAuthSuggest уже загружен.');
      initYandexAuthSuggest();
    } else {
      console.log('Загрузка YaAuthSuggest...');
      console.log('YaAuthSuggest не загружен.');
    }
  }, []);

  return <div id="yandex-auth-container" />;
};

export default YandexAuthButton;
