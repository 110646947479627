import { useState, useEffect, useContext } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Link,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { AuthContext } from "./../../App";

axios.defaults.withCredentials = true;

const PageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
`;

const WelcomeContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 3rem;
`;

const FooterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const SignUp = () => {
  // Объявите состояния для полей регистрации
  const [Email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [verificationDialogOpen, setVerificationDialogOpen] = useState(false);
  const [serverVerificationCode, setserverVerificationCode] = useState('');
  const [inputVerificationCode, setInputVerificationCode] = useState('');

  // Добавьте это
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);


  const handleVerificationDialogCancel = () => {
    axios.post('https://ra.nov.ru:8080/delete-unverified-user', {
      username: username, // ID пользователя, который был возвращен при регистрации
    }, { withCredentials: true });
    setVerificationDialogOpen(false);
  };
  
  const handleVerificationDialogSubmit = async () => {
    try {
      const response = await axios.post('https://ra.nov.ru:8080/verify', {
        email: Email,
        verificationCode: inputVerificationCode,
      }, { withCredentials: true });
  
      if (response.status === 200) {
        const { token } = response.data;
  
        // Сохраняем токен в куки
        localStorage.setItem('authToken', token);
  
        // Обновляем состояние isAuthenticated
        setIsAuthenticated(true);
  
        setVerificationDialogOpen(false);
        navigate('/');
      }
    } catch (error) {
      console.error('Ошибка при подтверждении кода верификации:', error);
      alert('Код неверен');
    }
  };
  
  
  const handleSignUp = async (e) => {
    e.preventDefault();
  
    // Проверка пароля на длину и допустимые символы
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      alert('Пароль должен содержать не менее 8 символов, включая буквы и цифры.');
      return;
    }
  
    // Проверка имени пользователя на допустимые символы
    const usernameRegex = /^[a-zA-Z0-9-_]+$/;
    if (!usernameRegex.test(username)) {
      alert('Имя пользователя может содержать только буквы, цифры, дефисы и подчеркивания.');
      return;
    }
  
    // Регистрация пользователя с помощью сервера
    try {
      const response = await axios.post('https://ra.nov.ru:8080/register', {
        username,
        email: Email,
        password,
        fullName,
        dateOfBirth: null, // Если у вас есть поле для ввода даты рождения, установите соответствующее значение
        gender: null, // Если у вас есть поле для ввода пола, установите соответствующее значение
        phone,
      }, { withCredentials: true });
 
      const { token } = response.data;
      
     // Здесь вы можете сохранить токен в localStorage или cookie, если хотите
      Cookies.set('authToken', token, { secure: true, sameSite: 'strict', expires: 1 });
     // document.cookie = `authToken=${token}; Secure; HttpOnly; SameSite=Strict`;
      setVerificationDialogOpen(true);
      // navigate("/chatgpt");
         
    } catch (error) {
      if (error.response && error.response.status === 409) {
        if (error.response.data.error === 'email') {
          alert('Email уже зарегистрирован. Пожалуйста, используйте другой email.');
        } else if (error.response.data.error === 'username') {
          alert('Такой пользователь уже зарегистрирован. Пожалуйста, выберите другое имя пользователя.');
        }
      } else {
        console.error('Ошибка при регистрации пользователя:', error);
        alert('Ошибка при регистрации пользователя. Пожалуйста, попробуйте еще раз.');
      }
    }
  };
  
  const handleLogin = () => {
    navigate('/signin');
    console.log('Здесь должна быть логика входа');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
    <PageWrapper>
      <Container component="main" maxWidth="xs">
        <WelcomeContainer>
        <Box maxWidth="300px" width="100%">
            <Box textAlign="center">
                <Typography component="h1" variant="h5">
                    Регистрация
                </Typography>
            </Box>
          <form onSubmit={handleSignUp}>
            <Grid container>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="Email"
                    label="Почта"
                    name="Email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {/* <Box textAlign="center"> */}
                <Typography variant="caption" color="textSecondary">
                 * На почту будет направлен проверочный код
                </Typography>
                {/* </Box> */}
            </Grid>
            <Grid item xs={12}>
                <TextField
                    variant="outlined"
                    margin="dense"
                    // required
                    fullWidth
                    id="phone"
                    label="Номер телефона"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
            </Grid>

             <Grid item xs={12}>
               <TextField
                 variant="outlined"
                 margin="dense"
                //  required
                 fullWidth
                 id="fullName"
                 label="Имя и фамилия"
                 name="fullName"
                 value={fullName}
                 onChange={(e) => setFullName(e.target.value)}
               />
             </Grid>
             <Grid item xs={12}>
               <TextField
                 variant="outlined"
                 margin="dense"
                 required
                 fullWidth
                 id="username"
                 label="Имя пользователя"
                 name="username"
                 value={username}
                 onChange={(e) => setUsername(e.target.value)}
               />
                {/* <Box textAlign="center"> */}
                <Typography variant="caption" color="textSecondary">
                 * Латинские буквы, цифры, - , _
                </Typography>
                {/* </Box> */}
             </Grid>
             <Grid item xs={12}>
               <TextField
                 variant="outlined"
                 margin="dense"
                 required
                 fullWidth
                 name="password"
                 label="Пароль"
                 type="password"
                 id="password"
                 value={password}
                 onChange={(e) => setPassword(e.target.value)}
               />
                {/* <Box textAlign="center"> */}
                <Typography variant="caption" color="textSecondary">
                 * Не менее 8 символов
                </Typography>
                {/* </Box> */}
             </Grid>
             <Grid item xs={12}>
             <Box marginTop={2} marginBottom={1}>  
               <Button
                 type="submit"
                 fullWidth
                 variant="contained"
                 color="primary"
                 onClick={handleSignUp}
               >
                 Далее
               </Button>
               </Box> 
             </Grid>
             <Grid container justifyContent="center">
               <Grid item>

                 <Link 
                 href="#" 
                 variant="body2"
                 onClick={handleLogin}
                 style={{ textDecoration: 'none', color: 'primary' }}
                 >
                    
                   {"Уже зарегистрированы? Вход"}
                 </Link>

               </Grid>
             </Grid>
           </Grid>
         </form>
         </Box>
       </WelcomeContainer>
     </Container>
     <Dialog
        open={verificationDialogOpen}
        onClose={handleVerificationDialogCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Подтверждение регистрации</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Введите проверочный код отправленный на указанную почту
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="verificationCode"
            label="Проверочный код"
            type="text"
            fullWidth
            value={inputVerificationCode}
            onChange={(e) => setInputVerificationCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleVerificationDialogCancel} color="primary">
            Отмена
          </Button>
          <Button onClick={handleVerificationDialogSubmit} color="primary">
            Проверить код
          </Button>
        </DialogActions>
      </Dialog>

     <FooterContainer>
        <Container maxWidth="md">
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                style={{ textDecoration: 'none', color: 'grey' }}
              >
                Информация
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                style={{ textDecoration: 'none', color: 'grey' }}
              >
                Помощь
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                style={{ textDecoration: 'none', color: 'grey' }}
              >
                Условия
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                style={{ textDecoration: 'none', color: 'grey' }}
              >
                Контакты
              </Link>
            </Grid>
        </Grid>
        </Container> 
        <Box mt={2} textAlign="center">
        <Typography variant="body2" color="text.secondary">
             &copy; 2023 created GPT-4 by Ra
        </Typography>
        </Box>
    </FooterContainer>    
   </PageWrapper>
   </AuthContext.Provider>
 );
};

export default SignUp;
