import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, useLocation  } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from "./../../App";
import YandexAuthButton from './../../Yandex';

axios.defaults.withCredentials = true;

const PageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
`;

const WelcomeContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  padding-bottom: 3rem;
`;

const FooterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const SignIn = () => {
  const [password, setPassword] = useState('');
  const [login, setLogin] = useState("");

  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  // If your state doesn't already have a from property, default to the main page
  const { from } = location.state || { from: { pathname: '/' } };
  // Добавьте это
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  
  useEffect(() => {
    if (isAuthenticated) {
      navigate(from.pathname, { replace: true }); // Navigate to the previous page
    }
  }, [isAuthenticated, navigate, from]);

  const handleLogin = async () => {
    try {
      const response = await axios.post('https://ra.nov.ru:8080/login', { login, password }, { withCredentials: true });
      if (response.status === 200) {
        const { token } = response.data;
        console.log("Token received:", token);
        localStorage.setItem('authToken', token);
        console.log("localStorage set:", localStorage.getItem('authToken'));
        console.log("Успешный вход");

        const tokenCheckResponse = await axios.get('https://ra.nov.ru:8080/api/check-token', {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        if (tokenCheckResponse.status === 200 && tokenCheckResponse.data.isValid) {
          setIsAuthenticated(true);
          navigate(from.pathname, { replace: true }); // Navigate to the previous page
        } else {
          console.error("Ошибка при проверке токена:", tokenCheckResponse.data.error);
          alert("Произошла ошибка при проверке токена. Пожалуйста, попробуйте еще раз.");
        }
      } else {
        const { error } = response.data;
        console.error("Ошибка при входе:", error);
        alert("Неправильный логин или пароль. Пожалуйста, попробуйте еще раз.");
      }
    } catch (error) {
      console.error("Ошибка при входе:", error);
      alert("Произошла ошибка при входе. Пожалуйста, попробуйте еще раз.");
    }
  };
  
  
  const handleRegister = () => {
    navigate('/signup');
    console.log('Здесь должна быть логика регистрации');
  };

  const handleForgotPassword = () => {
    console.log('Здесь должна быть логика восстановления пароля');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated }}>
    <PageWrapper>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <WelcomeContainer>
          <Box maxWidth="300px" width="100%">
            <Box textAlign="center">
            <Typography component="h1" variant="h5">
              Вход
            </Typography>
            </Box>
            <YandexAuthButton />
            <form>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="email"
                    label="Логин"
                    name="email"
                    value={login}
                    onChange={(e) => setLogin(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    name="password"
                    label="Пароль"
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                <Box marginTop={2} marginBottom={1}>  
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                  >
                    Войти
                  </Button>
                </Box> 
                </Grid>
                <Grid container>
                  <Grid item xs> {/* Добавьте paddingRight */}
                    <Link
                      href="#"
                      variant="body2"
                      //onClick={logout}
                      style={{ textDecoration: 'none', color: 'primary' }}
                    >
                    Забыли пароль?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      href="#"
                      variant="body2"
                      onClick={handleRegister}
                      style={{ textDecoration: 'none', color: 'primary' }}
                    >
                    Зарегистрироваться
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </WelcomeContainer>
      </Container>
      <FooterContainer>
        <Container maxWidth="md">
          <Grid container justifyContent="space-between" spacing={1}>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                style={{ textDecoration: 'none', color: 'grey' }}
              >
                Информация
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                style={{ textDecoration: 'none', color: 'grey' }}
              >
                Помощь
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                style={{ textDecoration: 'none', color: 'grey' }}
              >
                Условия
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="#"
                variant="body2"
                style={{ textDecoration: 'none', color: 'grey' }}
              >
                Контакты
              </Link>
            </Grid>
        </Grid>
        </Container> 
        <Box mt={2} textAlign="center">
        <Typography variant="body2" color="text.secondary">
             &copy; 2023 created GPT-4 by Ra
        </Typography>
        </Box>
    </FooterContainer>       
     
    </PageWrapper> 
    </AuthContext.Provider> 
      );
    };
    
    export default SignIn;