// TokenPage.js
import React from 'react';

const TokenPage = () => {
  return (
    <div>
      <script src="https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js"></script>
    </div>
  );
};

export default TokenPage;
