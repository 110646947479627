import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './App';

const TokenRedirect = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const getTokenFromUrl = () => {
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get('code');
    };

    const receiveToken = async () => {
      try {
        const token = getTokenFromUrl();

        if (!token) {
          throw new Error('Токен не найден в URL');
        }

        await handleYandexToken(token);

        setIsAuthenticated(true);
        // Отправка сообщения обратно в основное окно
        if (window.opener) {
          window.opener.postMessage({ isAuthenticated: true }, window.location.origin);
          window.close();
        } else {
          // Переход на главную страницу если окно не попап
          navigate('/');
        }

      } catch (error) {
        console.error('Ошибка получения токена:', error);
      }
    };

    receiveToken();
  }, [navigate, setIsAuthenticated]);

  async function handleYandexToken(yandexToken) {
    const response = await fetch("https://ra.nov.ru:8080/yandex-login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ yandexToken }),
    });

    if (!response.ok) {
      throw new Error("Ошибка при авторизации через Яндекс");
    }

    const data = await response.json();
    localStorage.setItem("jwtToken", data.token);
  }

  return <div>Авторизация через Яндекс...</div>;
};

export default TokenRedirect;
