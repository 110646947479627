import React from 'react';
import { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import SignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
import TokenPage from './TokenPage'; // Добавьте это
import TokenRedirect from './TokenRedirect'; // Добавьте это
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import ChatGPT from './pages/ChatGPT/ChatGPT';
import Cookies from 'js-cookie';
import { checkToken } from "./api";

export const AuthContext = createContext();

const AppContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  min-height: 100vh;
  background-color: #f7f7f7;
`;

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const authToken = localStorage.getItem("authToken");
      if (authToken) {
        const isTokenValid = await checkToken(authToken);
        setIsAuthenticated(isTokenValid);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    })();
  }, []);

  console.log("isAuthenticated:", isAuthenticated);
  console.log("isLoading:", isLoading);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <Router>
        <AppContainer>
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/token-page" element={<TokenPage />} /> {/* Добавьте это */}
            <Route path="/token-redirect" element={<TokenRedirect />} /> {/* Добавьте это */}
            <Route
              path="/"
              element={isAuthenticated ? <ChatGPT /> : <Navigate to="/signin" />}
            />
          </Routes>
        </AppContainer>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
