import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://ra.nov.ru:8080/api", // Измените порт на 3001 или тот, который используется вашим сервером
  // baseURL: "http://localhost:3001/api", 
  
});

export const checkToken = async (token) => {
  try {
    const response = await apiClient.get("/check-token", {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.status === 200;
  } catch (error) {
    if (error.response) {
      // Сервер ответил с ошибкой
      console.error(`Ошибка от сервера: ${error.response.status} - ${error.response.data}`);
    } else if (error.request) {
      // Запрос был сделан, но ответа не получено
      console.error("Ошибка запроса:", error.request);
    } else {
      // Другая ошибка, например, ошибка настройки запроса
      console.error("Ошибка настройки запроса:", error.message);
    }
    return false;
  }
};
