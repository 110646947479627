import React, { useState, useEffect } from "react";
import { Buffer } from "buffer";
import {
  Configuration,
  OpenAIApi,
} from "openai";
import { styled } from "@mui/system";
import {
  Paper,
  TextField,
  IconButton,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Popover,
  } from "@mui/material";
import TelegramIcon from "@mui/icons-material/Telegram";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/okaidia";
import { saveAs } from "file-saver";
import axios from 'axios';

function formatDate() {
  const now = new Date();
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const date = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
  const year = now.getFullYear();

  return `${hours}.${minutes}_${date}.${month}.${year}_chatGPT.txt`;
}

const configuration = new Configuration({
  apiKey: process.env.REACT_APP_OPENAI_KEY,
});
const openai = new OpenAIApi(configuration);

const sendRequestToChatGPT = async (messages, model, n) => {
  try {
    const completion = await openai.createChatCompletion({
      model,
      messages,
      n,
    });
    return completion.data.choices.map(choice => choice.message.content);
  } catch (error) {
    console.error('Error sending request to ChatGPT:', error);
    return ['Произошла ошибка при получении ответа от ChatGPT.'];
  }
};


// const sendRequestToDALL_E = async (prompt) => {
//   try {
//     const buffer = new Buffer.from(prompt, "utf-8");
//     buffer.name = "image.png";
//     const response = await openai.createImage({
//       prompt: prompt,
// 	  n: 1,
//       size: "1024x1024",
//     });
// 	return response.data.data[0].url;

//   } catch (error) {
//     console.error("Error sending request to DALL-E:", error);
//     return "Произошла ошибка при получении ответа от DALL-E.";
//   }
// };

const StyledPopover = styled(Popover)(({ theme }) => ({
  ".MuiPaper-root": {
    backgroundColor: "white",
    borderRadius: theme.shape.borderRadius,
  },
}));

const CodeHeader = styled('div')(({ theme, codeLanguage }) => ({

  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#1e1e1e',
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  padding: theme.spacing(0, 1),
  height: '1.5em',
  maxWidth: '90%',
}));

const CodeLanguage = styled('div')`
  font-size: 12px;
  color: white;
`;

const CopyButton = styled('div')({
  fontSize: '12px',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

const CodeContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#000000',
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderBottomRightRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  color: '#D3D3D3',
  whiteSpace: 'pre',
  overflowWrap: 'normal',
  fontFamily: 'monospace',
  marginBottom: theme.spacing(1),

  overflowX: 'auto',
  maxWidth: '90%', 
}));

const EmptyMessage = styled('div')`
  flex-grow: 1;
  pointer-events: none; // добавить эту строку
`;

const ChatContainer = styled(Box)`
  max-width: 900px;
  margin: 0 auto;
  padding: 0px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px); // вычитаем 44px паддинга + 16px отступа снизу
`;

const OutputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 95%;
  padding: 0px 8px 92px 8px;
  background-color: #f7f7f7;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  overflow-y: auto;
`;

const InputContainer = styled(Paper)`
  display: flex;
  justify-content: space-between; /* Изменяем свойство на justify-content: space-between */
  align-items: center;
  padding: 10px;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`;

const StyledTextField = styled(TextField)`
  width: 100%; /* Изменяем свойство на width: 70% */
  margin-left: 35px;
`;

const ModelSelect = styled(Select)`
  min-width: 110px;
  flex: 1;
  white-space: normal;
`;

const Message = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: ${({ role }) => (role === 'user' ? 'flex-end' : 'flex-start')};
  width: 100%;
  margin-bottom: ${({ role }) => (role === 'user' ? '-15px' : '-15px')};
`;

const MessageText = styled('pre')`
  padding: 8px;
  border-radius: 8px;
  background-color: ${({ role }) => (role === 'user' ? '#2196f3' : 'white')};
  color: ${({ role }) => (role === 'user' ? 'white' : 'black')};
  font-size: 15px;
  line-height: 1.4;
  white-space: pre-wrap;
  display: inline-block;
  max-width: 85%;
`;

const openImage = (url) => {
  window.open(url, '_blank');
};

const ChatGPT = () => {
const [messages, setMessages] = useState([]);
const [inputMessage, setInputMessage] = useState('');
const [showOutput, setShowOutput] = useState(false);
const [isLoading, setIsLoading] = useState(false);
const [selectedModel, setSelectedModel] = useState("gpt-4o-mini");
const [anchorEl, setAnchorEl] = useState(null);
const [selectedMessage, setSelectedMessage] = useState(null);
const [menuPosition, setMenuPosition] = useState({x: 0, y: 0});

const createTxt = (messagesToSave) => {
  const filename = formatDate();
  let textContent = "";
  messagesToSave.forEach((messagePair, index) => {
    textContent += `Вы: ${messagePair.user.content}\n`;
    textContent += `ИИ: ${messagePair.assistant.content}\n\n`;
  });

  const textBlob = new Blob([textContent], { type: "text/plain;charset=utf-8" });
  saveAs(textBlob, filename);
};

const handleMessageClick = (event, messageIndex) => {
  setAnchorEl(event.currentTarget);
  setSelectedMessage(messageIndex);
  setMenuPosition({x: event.clientX, y: event.clientY});
};

const handleClose = () => {
  setAnchorEl(null);
};

const handleSaveClick = () => {
  if (selectedMessage !== null) {
    createTxt([messages[selectedMessage]]);
  }
  setAnchorEl(null);
};

const handleSaveAllClick = () => {
  createTxt(messages);
  setAnchorEl(null);
};

const copyToClipboard = (text) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    // Резервный способ для копирования текста в буфер обмена
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  }
};

const handleSubmit = async (e) => {
  e.preventDefault();
  if (!inputMessage.trim()) return;

  setIsLoading(true);

  const newMessages = [  ...messages,  { user: { role: "user", content: inputMessage } },];

  let responses;
  if (selectedModel === "gpt-4o") {
    responses = await sendRequestToChatGPT(
      newMessages.map((messagePair) => messagePair.user),
      "gpt-4o",
      1
    );
  } else if (selectedModel === "gpt-a") {
    const lastMessage = newMessages[newMessages.length - 1];
    const promptForGptA = lastMessage.user.content + ". Ответь двумя афоризмами на данную ситуацию. Один афоризм должен быть от известного человека, а другой от персонажа";
    responses = await sendRequestToChatGPT(
      [{role: lastMessage.user.role, content: promptForGptA}],
      "gpt-4o-mini",
      1
    );
  } else if (selectedModel === "gpt-4o-mini") {  
    responses = await sendRequestToChatGPT(  
      newMessages.map((messagePair) => messagePair.user),
      "gpt-4o-mini",
      1 
    );
  }

  responses.forEach(response => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        user: { role: "user", content: inputMessage },
        assistant: { role: "assistant", content: response },
      },
    ]);
  });
  
  setInputMessage("");

  if (!showOutput) {
    setShowOutput(true);
  }

  setIsLoading(false);
};



const handleModelChange = (event) => {
  setSelectedModel(event.target.value);
  setMessages([]);
};

const renderAssistantContent = (content, msgIndex) => {
  const codeRegex = /```[\s\S]*?```/g;
  const parts = content.split(codeRegex).map((part) => part.trim());
  const codeBlocks = content.match(codeRegex);

  const elements = [];

  parts.forEach((part, index) => {
    if (part.length > 0) {
      elements.push(
        <React.Fragment key={`part-${msgIndex}-${index}`}>{part}</React.Fragment>
      );
    }

		if (codeBlocks && codeBlocks[index]) {
		  const codeBlockWithApostrophes = codeBlocks[index];
		 console.log('codeBlockWithApostrophes:', codeBlockWithApostrophes);  
		  const codeLanguageMatch = codeBlockWithApostrophes.match(/```(\S+)/);
		console.log('codeLanguageMatch:', codeLanguageMatch);   
		  const codeLanguage = codeLanguageMatch ? codeLanguageMatch[1] : null;
		console.log('codeLanguage:', codeLanguage); 
		  let codeBlock = codeBlockWithApostrophes.slice(3, -3).trim();
		  if (codeLanguage) {
			codeBlock = codeBlock.slice(codeLanguage.length).trim();
		  }
		
      elements.push(
        <React.Fragment key={`code-container-${msgIndex}-${index}`}>
          <CodeHeader onClick={(event) => event.stopPropagation()}>
            <CodeLanguage>{codeLanguage}</CodeLanguage>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {codeLanguage === "python" && (
                <IconButton
                  fontSize="small"
                  style={{ marginRight: -4 }}
                  
                >
                  <PlayArrowIcon style={{ fill: 'white' }} />
                </IconButton>
              )}
              <CopyButton onClick={(event) => { event.stopPropagation(); copyToClipboard(codeBlock); }}>
                Copy code
              </CopyButton>
            </div>
          </CodeHeader>
         
          <CodeContainer onClick={(event) => event.stopPropagation()}>
            
            <Highlight {...defaultProps} code={codeBlock} language={codeLanguage || "text"} theme={theme}>
              {({ className, style, tokens, getLineProps, getTokenProps }) => (
                  <pre
                  className={className}
                  style={{
                  ...style,
                  backgroundColor: "black", 
                  }}
                >
                {tokens.map((line, i) => (
                <div {...getLineProps({ line, key: i })}>
                  {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                  ))}
                </div>
                ))}
              </pre>
              )}
            </Highlight>


          </CodeContainer>
        </React.Fragment>
      );
    }
  });

  return elements;
};

return (
  <>
<ChatContainer>
	<form onSubmit={handleSubmit}>
		<InputContainer>
		  <FormControl variant="outlined">
			<InputLabel>Модель</InputLabel>
			<ModelSelect
			  value={selectedModel}
			  onChange={handleModelChange}
			  label="Модель"
			>
			  <MenuItem value="gpt-a">GPT-A</MenuItem>
			  <MenuItem value="gpt-4o">gpt-4o</MenuItem>
        <MenuItem value="gpt-4o-mini">gpt-4o-mini</MenuItem>
			</ModelSelect>
		  </FormControl>
		  <StyledTextField
			id="question"
			value={inputMessage}
			onChange={(e) => setInputMessage(e.target.value)}
			placeholder="Ваш вопрос к ИИ"
			disabled={isLoading}
			variant="outlined"
			InputProps={{
			  endAdornment: (
				<IconButton
				  type="submit"
				  disabled={isLoading}
				  color="primary"
				  edge="end"
				>
				  <TelegramIcon />
				</IconButton>
			  ),
			}}
		  />
		</InputContainer>

      </form>
		 <OutputContainer>
		  <EmptyMessage />
			{messages.map((messagePair, index) => (
			  <React.Fragment key={index}>
				<Message role={messagePair.user.role}>
				  <MessageText role={messagePair.user.role}>
					{messagePair.user.content}
				  </MessageText>
				</Message>
				<Message role={messagePair.assistant.role}>
        {selectedModel === "gpt-a" && (
            <MessageText
              role="assistant"
              onClick={(event) => handleMessageClick(event, index)}
            >
              {renderAssistantContent(messagePair.assistant.content, index)}
            </MessageText>
          )}

				  {selectedModel === "gpt-4o" && (
					<MessageText
					  role="assistant"
					  onClick={(event) =>
						handleMessageClick(event, index)
					  }
					>
					  {renderAssistantContent(
						messagePair.assistant.content, index
					  )}
					</MessageText>

				  )}
          {selectedModel === "gpt-4o-mini" && (  // Замените условие
          <MessageText
            role="assistant"
            onClick={(event) =>
              handleMessageClick(event, index)
            }
          >
            {renderAssistantContent(
              messagePair.assistant.content, index
            )}
          </MessageText>
        )}

				</Message>
									
			  </React.Fragment>
			))}
		</OutputContainer>

    </ChatContainer>
    <StyledPopover
      id="simple-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      onContextMenu={(event) => event.preventDefault()}
      anchorReference="anchorPosition"
      anchorPosition={{ top: menuPosition.y, left: menuPosition.x }}
    >
      <MenuItem onClick={handleSaveClick}>Сохранить</MenuItem>
      <MenuItem onClick={handleSaveAllClick}>Сохранить всё</MenuItem>
    </StyledPopover>
    </>
  );
};


export default ChatGPT;